import React, { useEffect, useState, useRef } from "react";
import "./App.css";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";
import { create } from "ipfs-http-client";
// import { useForm } from "react-hook-form";

export const StyledButton = styled.button`
  background: paleturquoise;
  border: 2px solid yellow;
  border-radius: 30px;
  color: black;
  font-family: lores-12, sans-serif;
  font-weight: bold;
  font-size: 28px;
  padding: 16px;
`;

export const StyledInput = styled.input`
background: azure;
border: 1px solid yellow;
border-radius: 25px;
color: black;
font-family: lores-12, sans-serif;
font-size: 20px;
padding: 10px;
margin: 5px;
width: 150px;
`;

export const StyledInputFrame = styled.div`
display: flex;
align-items: center;
flex-flow: column;
width: 350px;
height: 210px;
border: 1px solid yellow;
border-radius: 25px;
background: deepskyblue;
`;

export const StyledAddress = styled.div`
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
direction: rtl;
display: inline-block;
vertical-align: bottom;
width: 69px
`;

export const SupplyFrame = styled.div`
display: flex;
align-items: center;
flex-flow: column;
width: 250px;
padding: 5px;
margin: 5px;
border: 2px solid yellow;
border-radius: 25px;
background: paleturquoise;
`;

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [feedback, setFeedback] = useState("**You must specify a quantity (minimum: 1, maximum: 20) or transaction will fail.**");
  const [claimingNft, setClaimingNft] = useState(false);
  const [qty, setQty] = useState('');
  
  //  const supply = blockchain.smartContract.methods.totalSupply().call();

  const claimNFTs = (_amount) => {
    setClaimingNft(true);
    blockchain.smartContract.methods
      .mint(blockchain.account, _amount)
      .send({
        to: "0xcaca4e237f30d189b369f208a754e6b0a1199bbf",
        from: blockchain.account,
        value: blockchain.web3.utils.toWei(
          (0.015 * _amount).toString(),
          "ether"
        ),
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, an error occurred.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        setFeedback("Thank you for your order. You may order again if you like.");
        setClaimingNft(false);
      });
  };

  useEffect(() => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  }, [blockchain.smartContract, dispatch]);

  return (
    <s.Screen>
      {blockchain.account === "" || blockchain.smartContract === null ? (
        <s.Container flex={1} ai={"center"} jc={"center"} background-color={"#84c8ff"}>
          <s.TextTitle>Connect your MetaMask wallet to order your breakfast</s.TextTitle>
          <s.SpacerLarge />
          <StyledButton
            onClick={(e) => {
              e.preventDefault();
              dispatch(connect());
            }}
          >
            CONNECT WALLET
          </StyledButton>
          <s.SpacerLarge />
          {blockchain.errorMsg !== "" ? (
            <s.TextDescription>{blockchain.errorMsg}</s.TextDescription>
          ) : null}
        </s.Container>
      ) : (
        <s.Container flex={1} ai={"center"} jc={"center"}>
          <s.TextDescription style={{ textAlign: "center" }}>
            {feedback}
          </s.TextDescription>
          <s.SpacerLarge />
          <StyledInputFrame>
            <s.SpacerSmall />
            <StyledInput 
            onKeyPress={(e) => {if (!/[0-9]/.test(e.key)) {e.preventDefault();}}}
            type="text" 
            value={qty} 
            onChange={(e) => setQty(e.target.value)} 
            placeholder="quantity"
            />
          <s.SpacerSmall />
          <StyledButton
            disabled={claimingNft ? 1 : 0}
            onClick={(e) => {
              e.preventDefault();
              claimNFTs(qty);
            }}
          >
            {claimingNft ? "RECEIVING ORDER" : "ORDER BREAKFAST"}
          </StyledButton>
          <s.SpacerSmall />
          <s.TextDescription>
            0x<StyledAddress>{blockchain.account}</StyledAddress>
          </s.TextDescription>
          <s.SpacerSmall />
          </StyledInputFrame>
          <s.SpacerSmall />
          <SupplyFrame>
          <s.TextDescription>
            {data.totalSupply} of 7777 ordered
          </s.TextDescription>
          </SupplyFrame>
        </s.Container>
      )}
    </s.Screen>
  );
}

export default App;
